import { LogType } from "../../api/types";
import { useLogs } from "../../providers/LogProvider";
import { Loader } from "../Loader";
import { LogContent, LogDate, LogExpand, LogWorker } from "./LogContent";
import {
  StyledDate,
  StyledRow,
  StyledTable,
  StyledWorker,
} from "./Table.styles";

export const Table = ({ logs }: { logs: LogType[] }) => {
  const { openedLogs, setOpenedLogs, highlightedLog, isLoading, liveTail } =
    useLogs();

  const handleClick = (id: string) => {
    if (!openedLogs.includes(id)) {
      setOpenedLogs([...openedLogs, id]);
    }
  };

  if (logs.length === 0 && !isLoading) {
    if (liveTail) {
      return (
        <StyledTable>
          <div style={{ padding: "10px" }}>Waiting for data...</div>
        </StyledTable>
      );
    }
    return (
      <StyledTable>
        <div style={{ padding: "10px" }}>No data found...</div>
      </StyledTable>
    );
  }
  return (
    <div style={{ position: "relative" }}>
      {isLoading && !liveTail && <Loader />}
      <StyledTable>
        {logs.map((log) => (
          <StyledRow
            key={log.id}
            id={log.id}
            withBackground={highlightedLog === log.id}
            onClick={() => handleClick(log.id)}
          >
            <LogExpand id={log.id} />

            <StyledDate style={{ width: "162px" }}>
              <LogDate id={log.id} date={log.date} log={log} />
            </StyledDate>

            <StyledWorker>
              <LogWorker row={log} />
            </StyledWorker>

            <LogContent
              key={log.id}
              text={log.text}
              id={log.id}
              metadata={log.metadata}
            />
          </StyledRow>
        ))}
      </StyledTable>
    </div>
  );
};
