import { Box } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { Flex, Form, Layout, Input, Typography, Button, Modal } from "antd";
import { useState } from "react";
import Turnstile from "react-turnstile";
import { turnstileSiteKey } from "../..";

const Login = () => {
  const { onLogin } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");

  return (
    <>
      <div className="bg-gray-900">
        <main>
          {/* Hero section */}
          <div className="relative isolate overflow-hidden min-h-screen">
            <div style={{ padding: "20px" }}>
              <img className="h-full max-h-10" src="/logo-horizontal.png" />
            </div>
            <div className="flex flex-col justify-center items-center d mt-8">
              <div className="my-12 flex flex-col items-center">
                <Typography.Title>LOGIN</Typography.Title>
              </div>
            </div>

            <svg
              aria-hidden="true"
              className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
              <defs>
                <pattern
                  x="50%"
                  y={-1}
                  id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                <path
                  d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                width="100%"
                height="100%"
                strokeWidth={0}
              />
            </svg>
            <div
              aria-hidden="true"
              className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
            >
              <div
                style={{
                  clipPath:
                    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                }}
                className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
              />
            </div>

            <Flex vertical align="center" justify="center">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={(values) =>
                  onLogin({ ...values, turnstileToken: token })
                }
                onFinishFailed={() => console.log("failed")}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
                <Turnstile
                  theme="dark"
                  sitekey={turnstileSiteKey}
                  fixedSize
                  appearance="execute"
                  refreshExpired={"auto"}
                  onVerify={(token) => {
                    setToken(token);
                  }}
                />
              </Form>
              <Flex justify="center">
                <Button
                  type="text"
                  style={{
                    marginBottom: 10,
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Are you Heroku user?
                  </span>
                </Button>
              </Flex>
            </Flex>
          </div>
        </main>
      </div>

      <Modal
        title="Heroku login"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>
          Heroku users can login by clicking LogAbyss app that is listed in
          Installed add-ons on Heroku
        </p>
        <img style={{ width: "100%", height: "auto" }} src="./scr/addon.png" />
      </Modal>
    </>
  );
};

export default Login;
